import moment from "moment/moment";
import capitalize from "./capitalize";
import helpers from "../tools/helpers";

export default function getHumanDateTime(
  startMoment,
  endMoment,
  hideBookingEndTime = false
) {
  const sameDay = startMoment.isSame(endMoment, "day");

  // Function to format the date and capitalize the first letter of the weekday
  const formatWithCapitalizedWeekday = (dateTime, format) => {
    const formattedString = moment(dateTime).format(format);
    return capitalize(formattedString);
  };

  const startDate = formatWithCapitalizedWeekday(
    startMoment,
    helpers.fullWithMonthTextAndDate
  );
  const endDate = formatWithCapitalizedWeekday(
    endMoment,
    helpers.fullWithMonthTextAndDate
  );

  // Format time using timeNotation function
  const startTimeFormatted = startMoment.format("HH:mm");
  const endTimeFormatted = endMoment.format("HH:mm");

  let subtitle = sameDay
    ? `${startDate}, ${startTimeFormatted} - ${endTimeFormatted}`
    : `${startDate} ${startTimeFormatted} - ${endDate} ${endTimeFormatted}`;

  if (hideBookingEndTime) {
    subtitle = sameDay
      ? `${startDate}, ${startTimeFormatted}`
      : `${startDate} ${startTimeFormatted} - ${endDate}`;
  }

  return capitalize(subtitle);
}
