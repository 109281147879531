import { render, staticRenderFns } from "./CCalendarEmployeePanel.vue?vue&type=template&id=2a270127&scoped=true&"
import script from "./CCalendarEmployeePanel.vue?vue&type=script&lang=ts&"
export * from "./CCalendarEmployeePanel.vue?vue&type=script&lang=ts&"
import style0 from "./CCalendarEmployeePanel.vue?vue&type=style&index=0&id=2a270127&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a270127",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VBtn,VIcon,VImg})
